import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Collapse, IconButton, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import Address from '../../components/address/Address.jsx';
import { useAPI } from '../../context/APIContext.jsx';
import Env from './Env.jsx';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import PriceGov from './PriceGov.jsx';
import { useTheme, useMediaQuery } from '@mui/material';
import '../../css/styles.css';
import Divider from '@mui/material/Divider/Divider.js';
import Typography from '@mui/material/Typography';
import { travel, walkingRadius } from '../../config/Range';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useData } from '../../context/DataContext.jsx';

export const Condition = ({ onQuery }) => {
    const { handleApiParam, handleApiMutiParam } = useAPI();
    const { result } = useData();
    const [isExpanded, setIsExpanded] = useState(null);
    const [zIndexGrid, setZindexGrid] = useState(2);
    const [zIndexIcon, setZindexIcon] = useState(3);
    const theme = useTheme();
    const [iconPosition, setIconPosition] = useState({
        condition: { top: 10, left: '34vw' }
    });
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const [formData, setFormData] = useState({
        county: null,
        district: null,
        address: null,
        env_categories: null,
        radius: 350,
        travel: 1,
        price_govs: {
            deal_date_start: '2019-01-01',
            age: 2,
            building_shifting_total_tw: 1,
            building_state: '住宅大樓(11層含以上有電梯)',
            // building_pattern_room: 0,
            // shifting_floor: null
        }
    });
    const GridColumnSpacingAddr = { xs: 1, sm: 2, md: 3 }
    const GridItemSizeAddr = { county: { xs: 12, sm: 6, md: 6 }, district: { xs: 12, sm: 6, md: 6 }, address: { xs: 12, sm: 12, md: 12 } }

    useEffect(() => {
        let newIconPosition;
        if (matchesXS) {
            newIconPosition = { condition: { top: 10, left: '80vw' } };
            setIconPosition(newIconPosition);
        }
        else if (matchesSM) {
            newIconPosition = { condition: { top: 10, left: '80vw' } };
            setIconPosition(newIconPosition);
        }
        else {
            newIconPosition = { condition: { top: 10, left: '34vw' } };
            setIconPosition(newIconPosition);
        }

        if (result) {
            resetZindex(false);
            setIsExpanded(false);
        }
        else {
            resetZindex(true);
            setIsExpanded(true);
        }


    }, [])

    const handleToggle = () => {
        const newIsExpanded = !isExpanded;
        resetZindex(newIsExpanded);
        setIsExpanded(newIsExpanded);
    };

    const handleChange = (event) => {
        handleApiParam(event, formData, setFormData);
    };

    const handleMuitChange = (values) => {
        handleApiMutiParam(values, formData, setFormData);
    }

    const handleQuery = (event) => {
        event.preventDefault();
        resetZindex(false);
        setIsExpanded(false);
        onQuery(formData);
    }

    const resetZindex = (newIsExpanded) => {
        const newZindexGrid = newIsExpanded === true ? 2 : -1; //2:蓋過搜尋結果按鈕(1), -1:確保不會顯示
        const newZindexIcon = newIsExpanded === true ? 3 : 1; //3:蓋過Grid(2), 1:顯示在地圖前面(0)
        setZindexGrid(newZindexGrid);
        setZindexIcon(newZindexIcon);
    }

    return (
        <>
            <Grid container >
                <Grid item xs={12} sm={4} md={4} className='sideMenu' sx={{ zIndex: zIndexGrid }}>
                    <Collapse
                        in={isExpanded}
                        sx={{ right: 0, border: '1px solid #1976d2', backgroundColor: 'white' }}
                    >
                        <Divider textAlign="center" sx={{ marginTop: '10px' }}>
                            <Typography sx={{ cursor: 'pointer' }}>範圍與地址資訊</Typography>
                        </Divider>
                        <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl required disabled fullWidth>
                                    <InputLabel id="travel_select_label">行動方式</InputLabel>
                                    <Select
                                        labelId="travel_select_label"
                                        name="travel"
                                        id="travel"
                                        label="行動方式"
                                        onChange={handleChange}
                                        value={formData.travel}
                                    >
                                        {Object.entries(travel).map(([key, value]) => (
                                            <MenuItem key={value} value={value}>{key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl required disabled fullWidth>
                                    <InputLabel id="radius_select_label">範圍</InputLabel>
                                    <Select
                                        labelId="radius_select_label"
                                        name="radius"
                                        id="radius"
                                        label="範圍"
                                        onChange={handleChange}
                                        value={formData.radius}
                                    >
                                        {Object.entries(walkingRadius).map(([key, value]) => (
                                            <MenuItem key={value} value={value}>{key}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Address
                                    handleChange={handleChange}
                                    handleMuitChange={handleMuitChange}
                                    GridColumnSpacing={GridColumnSpacingAddr}
                                    GridItemSize={GridItemSizeAddr}
                                />
                            </Grid>
                            {/*社區座標or地址*/}
                            {/* <Grid item xs={12} sm={12} md={12}>
                                <House handleMuitChange={handleMuitChange} />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12}>
                                <PriceGov formData={formData} handleMuitChange={handleMuitChange} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {/*機能選單，ex:超商、捷運、公車站、賣場 */}
                                <Env handleMuitChange={handleMuitChange} />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
            <Box sx={{ position: 'absolute', zIndex: zIndexIcon, width: '40px', top: iconPosition.condition.top, left: isExpanded ? iconPosition.condition.left : 10, transition: 'left 0.5s ease' }}>
                <IconButton sx={{ marginBottom: 1, bgcolor: '#1976d2', '&:hover': { backgroundColor: 'CornflowerBlue' } }}
                    onClick={handleToggle}
                >
                    {isExpanded ? <ChevronLeftIcon style={{ color: 'white' }} /> : <ChevronRightIcon style={{ color: 'white' }} />}
                </IconButton>
                {isExpanded &&
                    <>
                        <Tooltip title="查詢"
                            sx={{
                                typography: 'body2',
                                backgroundColor: 'primary.dark',
                                color: 'white',
                                '& .MuiTooltip-arrow': {
                                    color: 'primary.dark',
                                },
                            }}
                            arrow
                        >
                            <IconButton
                                sx={{
                                    marginBottom: 1,
                                    bgcolor: 'rgba(128, 128, 128, 1)',
                                    '&:hover': { bgcolor: '#ADADAD' }
                                }}
                                onClick={handleQuery}>
                                <SearchIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </Box>
        </>
    )
}

export default React.memo(Condition);
