import React, { useEffect } from 'react';
import { LoadingOverlay } from '../../components/loading/Loading.jsx';
import apiUri from '../../config/Api.js';
import { useAPI } from '../../context/APIContext.jsx';
import { useData } from "../../context/DataContext";
import { Condition } from './Condition.jsx';
import LeafletMap from './LeafletMap.jsx';
import SearchResultSideBar from './SearchResultSideBar.jsx';
import Link from '@mui/material/Link';

const Home = () => {
  const { post, loading, showAlert } = useAPI();
  const { storeCondition, storeResult, result, clearCondition, clearResult, clearReport, condition, report } = useData();

  useEffect(() => {
    console.log(result);
    console.log(condition);
    console.log(report);
  })

  useEffect(() => {
    return () => {
      clearCondition();
      clearResult();
      clearReport();
    };
  }, [])

  const handleQuery = (newCondition) => {
    queryHome(newCondition);
  }

  const queryHome = async (newCondition) => {
    const resData = await post(apiUri.queryHome, newCondition, null);
    if (resData.code === 0) {
      showAlert('查詢成功', 'success');
      handleQueryResData(resData, newCondition);
    }
    else {
      clearCondition();
      clearResult();
      clearReport();
    }
  }

  const handleQueryResData = (newResult, newCondition) => {
    storeCondition(newCondition);
    storeResult(newResult);
    clearReport();
  }

  return (
    <div style={{ position: 'absolute' }}>
      {loading && <LoadingOverlay />}
      <Condition onQuery={handleQuery} />
      {(result && (result.env_infos || result.places)) &&
        <SearchResultSideBar />
      }
      <div>
        <LeafletMap />
      </div>
      <div style={{ position: 'fixed', zIndex: 1, left: 0, bottom: 0, backgroundColor: 'lightgray', paddingLeft: 8, paddingRight: 8 }}>
        <Link href="/Policy" variant="body2">
          隱私權政策
        </Link>
      </div>
    </div>
  );
}

export default React.memo(Home);
